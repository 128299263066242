import React, { useCallback } from 'react'

export default function AnchorLink({to, id, children, onClick, ...rest}) {
  const smoothScroll = useCallback((e) => {
    e.preventDefault()
    const id = to.replace('#','')
    const element = document.getElementById(id)
    if (element) element.scrollIntoView({behavior:"smooth"})
  }, [])

  return (
    <a {...rest} onClick={smoothScroll}>
      {children}
    </a>
  )
}
