import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import AnchorLink from '../components/AnchorLink'
import '../styles/Prose'
import { useClasses } from '../lib/ReactUtils'

export default function Privacy() {
  const root = useClasses()
  return (
    <Layout>
      <SEO title='Privacy' description='Our privacy policy'/>
      <div className='main mb-20' ref={root}>
        <div className="heading-separator"/>
        <h1 className="title">Privacy Policy</h1>
        <section className='prose'>
          <h2>Introduction</h2>
          <p>OneOffice (“we”) is / are committed to keeping your data safe. Any time we collect or process your data, we’ll do so legally. That means that we will only process data with your explicit permission and/or for necessary marketing and sales communication. This page will explain, in some detail:</p>
          <ol>
            <li><AnchorLink to='#whoCollects'>Who collects the data</AnchorLink></li>
            <li><AnchorLink to='#whatWeCollect'>What data we collect</AnchorLink></li>
            <li><AnchorLink to='#howWeCollect'>How we collect data</AnchorLink></li>
            <li><AnchorLink to='#whyWeCollect'>Why we collect data</AnchorLink></li>
            <li><AnchorLink to='#howWeUse'>How we use the data</AnchorLink></li>
            <li><AnchorLink to='#yourRights'>Your data rights</AnchorLink></li>
          </ol>
          <p>While you’re on our site, we’ll collect and store information about you in accordance with this policy. If you don’t agree with this, please turn tracking off, or, exit the site entirely – we won’t take it personally.</p>
          <p>We are a global company, so when you provide us personal data, that data may be used, processed, or stored anywhere in the world, including in countries that have data protection laws that are different to the country in which you reside. However, OneOffice has policies and procedures in place that are constructed to safeguard and ensure an equal level of protection is applied across the entire organization and in accordance with this Policy.</p>
          <p>This Privacy Policy (which we’ll refer to as “Policy” going forward), describes practices with regards to our marketing websites (“website” going forward), e-mails, affiliates, virtual and in-person events, offline sales, and additional marketing and sales activities.</p>

          <h2 id='whoCollects'>Who collects the data</h2>
          <p>All data is collected on behalf of OneOffice and its subsidiaries. We can be contacted by email or via phone</p>

          <h2 id='whatWeCollect'>What data we collect</h2>
          <p>We will never collect data about you without clearly stating what information we are requesting and what we will use it for. When we do collect data about you, it will be:</p>
          <ul>
            <li>Your first and last name, e-mail address, phone number</li>
            <li>Company information including name, physical address, size</li>
            <li>Geographical location, IP address, including country, state or province</li>
            <li>Behavioral or demographical information</li>
            <li>Data from marketing opt-in lists, campaigns, surveys, or publicly available information</li>
            <li>Collaboration information</li>
          </ul>

          <h2 id='howWeCollect'>How we collect data</h2>
          <p>Through your interaction with us or use of this website, we may collect the following personal information that identifies you as an individual:</p>
          <ul>
            <li>Information you provide through form submissions on our websites. This includes when you contact us, subscribe to our marketing communications, download collateral, register or attend an OneOffice event or partner event, and purchase products or services.</li>
            <li>Information you provide when applying for a job.</li>
            <li>Information that you submit to us or OneOffice employees when you want to engage in business or potential business with us.</li>
            <li>Information you provide us through our website, in-personal conversations or events.</li>
            <li>Information you provide us through authorizing our App via Google or Microsoft login.</li>
            <li>Information from surveys, reviews, or inquiries that you voluntarily take part in.</li>
            <li>Information you submit to us via social media channels.</li>
          </ul>
          <p>We don’t intentionally solicit, collect or process data for anybody under the age of 18. If it happens by accident, we delete it immediately.</p>

          <h2 id='whyWeCollect'>Why we collect data</h2>
          <p>We collect your data to better provide you information and determine whether our tools would be a good fit for you. In those cases where we use legitimate interest as lawful basis for our data processing, we will always balance our interest with your right to data privacy.</p>
          <p>The legitimate interest basis makes processing lawful only if it is necessary of the controller. It requires transparency and the successful outcome of a balancing test between your right to privacy and our interests. Transparency means it shall be clear to you which data is used and how it’s used. A balancing test requires us to take into consideration factors such as:</p>
          <ul>
            <li>That our interest as controller is significant compared to the impact on your data privacy</li>
            <li>That we only process what can reasonably be expected by you</li>
            <li>That we cannot achieve the same by not processing the data</li>
          </ul>
          <p>As such, our purposes are always legitimate, and the disadvantages to you are few.</p>
          <p>These are our specific purposes for processing your data:</p>
          <ul>
            <li>Identifying and targeting educational institutions that have an interest and need for a productivity and collaboration system</li>
            <li>Promoting our productivity and collaboration system to interested potential customers</li>
            <li>Nurturing relationships with interested potential customers, whether we initially encountered them via telephone, web or in-person.</li>
            <li>Managing customer relationships, to better communicate any updates or changes they might be interested in</li>
          </ul>

          <h2 id='howWeUse'>How we use the data</h2>
          <p>We only collect or process your data we are performing a contract, when we have consent, or when we have a legitimate interest.</p>
          <p>Your <strong>consent</strong> must be clear, unambiguous and freely given. When we request information, we have to tell you what we’d like to know and why. <strong>Legitimate interests</strong> are uses that are necessary to provide a product or service, or those that could be reasonably expected by the person whose information is being collected.</p>
          <p>If no activity, interest, intent or need can be identified within a 2-year period, we delete your data. Please note that third-party sites may retain it. The only time we will retain your personal information for longer than is outlined in this Policy is when it is required by law.</p>
          <p>The chart below explains how we use the data collected through each of our channels, the purpose and the legal basis we have for doing so.</p>
          <div>
            <table>
              <thead>
                <tr>
                  <th>How we collect data</th>
                  <th>Why we collect data</th>
                  <th>How we use the data</th>
                  <th>The legal basis for our use</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>You sign up or login via Google or Microsoft login.</td>
                  <td>To allow for proper functioning of the application in an office setting.</td>
                  <td>We use your data so you can work more effectively (e.g. submitting a Google Doc as assignment), to generate reports by your leadership. We only store your first, last name and email. No other Personally Identifiable information is stored on our servers.</td>
                  <td>Consent</td>
                </tr>
                <tr>
                  <td>You fill out a form on our website.</td>
                  <td>
                    Identifying and targeting potential institutions that have an interest and need for a productivity and collaboration system.
                    Nurturing relationships with interested potential customers.
                    Managing customer relationships, to better communicate any updates or changes they might be interested in.
                  </td>
                  <td>
                    When you fill out a form, we let you know why and how we’ll use the data.
                    This includes requests to contact us, subscribe to our marketing communications or newsletter, download collateral, register or attend an OneOffice event or partner event, and purchase products or services, or identify a company&#8217;s need for a productivity and collaboration system.
                  </td>
                  <td>Legitimate Interest</td>
                </tr>
                <tr>
                  <td>We collect your information from a publicly available source (e.g. you have your contact information listed on your website.)</td>
                  <td>Identifying and targeting institutions that have an interest and need for a productivity and collaboration.</td>
                  <td>We use this information to ensure that the data we are processing of yours is correct and up to date, as well as to correctly profile whether you have potential, intent or a need to purchase a productivity and collaboration system.</td>
                  <td>Legitimate Interest</td>
                </tr>
                <tr>
                  <td>We’ve partnered with a related company to create a cross-promotional opportunity.</td>
                  <td>Identifying and targeting educational institutions that have an interest and need for a productivity and collaboration.</td>
                  <td>We use this data to better identify customers who might have a potential need or interest in productivity and collaboration.</td>
                  <td>Legitimate Interest</td>
                </tr>
                <tr>
                  <td>We talk to you at an event or tradeshow.</td>
                  <td>
                    Nurturing relationships with interested potential customers.
                    Managing customer relationships, to better communicate any updates or changes they might be interested in.
                  </td>
                  <td>We use your information to build relationships, determine whether you may have a need or intent to purchase a productivity and collaboration system, and to obtain explicit consent to be added to our marketing distribution email list for further nurturing.</td>
                  <td>Legitimate Interest</td>
                </tr>
                <tr>
                  <td>We communicate with you via our social media channels.</td>
                  <td>
                    Nurturing relationships with interested potential customers.
                    Managing customer relationships, to better communicate any updates or changes they might be interested in.
                  </td>
                  <td>We build brand awareness, and link you to information on our website that we believe may prove useful.</td>
                  <td>Legitimate Interest</td>
                </tr>
                <tr>
                  <td>You sign up for a particular piece of email marketing.</td>
                  <td>
                    Promoting our productivity and collaboration system to interested potential customers.
                    Managing customer relationships, to better communicate any updates or changes they might be interested in.
                  </td>
                  <td>OneOffice has a legitimate interest to send singular emails for one intended purpose, or at the request, or knowledge of the recipient.</td>
                  <td>Legitimate Interest</td>
                </tr>
                <tr>
                  <td>You sign up to be on our marketing distribution list.</td>
                  <td>
                    Promoting our productivity and collaboration system to interested potential customers.
                    Managing customer relationships, to better.
                  </td>
                  <td>OneOffice will explicitly ask if a prospect would like to be added to the distribution list to receive marketing communication on products, services, topics that may be of interest to them.</td>
                  <td>Consent</td>
                </tr>
                <tr>
                  <td>One of our representatives talks to you in person, via drop-in or at a networking event.</td>
                  <td>
                    Nurturing relationships with interested potential customers
                    Managing customer relationships, to better communicate any updates or changes they might be interested in
                  </td>
                  <td>We follow up on high-level marketing activity and buyer behavior in order to reach out, establish contact, create or foster a relationship, and follow up for the purposes of driving sales and revenue.</td>
                  <td>Legitimate Interest</td>
                </tr>
              </tbody>
            </table>
          </div>

          <h2 id='yourRights'>Your data rights</h2>
          <p>When it comes to your personal data, you have full rights:</p>
          <ul>
            <li>You have the right to know whether we have your information.</li>
            <li>You have the right to access any information we have about you.</li>
            <li>You have the right to correct or update your information.</li>
            <li>You have the right to unsubscribe from our marketing materials, whenever you choose.</li>
            <li>You have the right to tell us not to process your data for marketing.</li>
            <li>You have the right to withdraw consent to use your information (Note that this withdrawal does not retroactively affect the lawfulness of any processing, nor does it affect processing that does not rely on consent).</li>
            <li>You have the right to talk about your personal data with our Data Protection Officer.</li>
            <li>You have the right to lodge a complaint if you think we’ve mishandled your data.</li>
            <li>You have the right to make us delete any information you’ve consented to share.</li>
          </ul>
          <p>If you want to exercise any of these rights, you have a number of different ways to do so. You can:</p>
          <ul>
            <li>Contact our DPO via email</li>
            <li>Unsubscribe from any or all of our services</li>
            <li>Lodge a complaint with a <a href='http://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080'>supervisory authority</a></li>
          </ul>
          <p>All requests will be processed within one month.</p>
        </section>
      </div>
    </Layout>
  )
}
